<template>
  <div class="impressum q-ma-xl" style="max-width: 1200px">
    <div class="row fit">
      <div class="col-sm-12 col-md-5">
        <h2 class="text-h2">Impressum</h2>
        <p>
          Martin Lacroix<br />
          Hunfeld 5<br />
          35396 Gießen<br /><br />
          <a href="mailto:ml@martin-lacroix.de">ml@martin-lacroix.de</a><br />
          0176 30 50 20 47<br /><br />
          <a href="https://www.martin-lacroix.de">www.martin-lacroix.de</a
          ><br />
        </p>

        <h5 class="text-h5">
          Verantwortlicher nach § 55 Abs. 2 RStV [Hinweis: demnächst § 18 Abs. 2
          MStV]:
        </h5>
        <p>
          Martin Lacroix<br />
          Hunfeld 5<br />
          35396 Gießen<br />
        </p>
      </div>
      <div class="col-sm-0 col-md-2"></div>
      <div class="col-sm-12 col-md-5">
        <h2 class="text-h2">Imprint</h2>
        <p>
          Martin Lacroix<br />
          Hunfeld 5<br />
          35396 Gießen<br /><br />
          <a href="mailto:ml@martin-lacroix.de">ml@martin-lacroix.de</a><br />
          0176 30 50 20 47<br /><br />
          <a href="https://www.martin-lacroix.de">www.martin-lacroix.de</a
          ><br />
        </p>
        <h5 class="text-h5">
          Responsible according to § 55 para. 2 RStV [Note: soon § 18 para. 2
          MStV]:
        </h5>
        <p>
          Martin Lacroix<br />
          Hunfeld 5<br />
          35396 Giessen
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Impressum",
};
</script>
